<template>
  <div class="h100">
    <!-- <el-dialog width="50%" @close="pauseisVideoSee"
      :visible.sync="dialogWatch"
      append-to-body >

        <video :id="hid" class="centeredVideo"  controls autoplay width="100%">Your browser is too old which doesn't support HTML5 video.</video>
    </el-dialog>-->

    <el-dialog
      width="50%"
      @close="pauseisVideoSee"
      :visible.sync="dialogWatch"
      append-to-body
    >
      <ck-player
        :sourceUrl="livingUrl"
        @playStatus="changePlayStatus"
      ></ck-player>
    </el-dialog>

    <el-dialog
      width="50%"
      @close="pauseisRecordSee"
      :visible.sync="dialogRecord"
      append-to-body
    >
      <video
        :src="recordUrl"
        controls
        autoplay
        width="100%"
        height="100%"
      ></video>
    </el-dialog>

    <el-dialog
      width="50%"
      @close="pauseisVideoSee"
      :visible.sync="dialogVisible"
      append-to-body
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="摄像头编号" prop="identify">
          <el-input v-model="ruleForm.identify"></el-input>
        </el-form-item>
        <el-form-item label="摄像头名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <!--    <el-form-item prop="statusType" label="摄像头状态">
             <el-select v-model="ruleForm.statusType" @change="update(scope.row)" placeholder="请选择摄像头来源">
              <el-option label="关闭" :value="0||false || undefined"></el-option>
              <el-option label="开启" :value="1"></el-option>
              <el-option label="重播" :value="2"></el-option>
            </el-select>
        </el-form-item>-->
        <el-form-item label="摄像头来源" prop="origin">
          <el-select v-model="ruleForm.origin" placeholder="请选择摄像头来源">
            <el-option label="海康" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重播时间">
          <el-col>
            <el-form-item prop="replayTime">
              <el-input type="number" v-model="ruleForm.replayTime">
                <template slot="append">秒</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="录制时间">
          <el-col>
            <el-form-item prop="recordTime">
              <el-input type="number" v-model="ruleForm.recordTime">
                <template slot="append">秒</template></el-input
              >
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >立即创建</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
      width="50%"
      @close="updVideoSee"
      :visible.sync="updDialogVisible"
      append-to-body
    >
      <el-form
        :model="updDataForm"
        :rules="rules"
        ref="updDataForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="摄像头编号" prop="identify">
          <el-input v-model="updDataForm.identify"></el-input>
        </el-form-item>
        <el-form-item label="摄像头名称" prop="name">
          <el-input v-model="updDataForm.name"></el-input>
        </el-form-item>
        <el-form-item prop="statusType" label="摄像头状态">
          <el-select
            v-model="updDataForm.statusType"
            @change="update(scope.row)"
            placeholder="请选择摄像头来源"
          >
            <el-option
              label="关闭"
              :value="0 || false || undefined"
            ></el-option>
            <el-option label="开启" :value="1"></el-option>
            <el-option label="重播" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="摄像头来源" prop="origin">
          <el-select
            v-model="updDataForm.origin"
            placeholder="请选择摄像头来源"
          >
            <el-option label="海康" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="重播时间">
          <el-col>
            <el-form-item prop="replayTime">
              <el-input v-model.number="updDataForm.replayTime">
                <template slot="append">秒</template></el-input
              >
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="直播地址">
          <el-col>
            <el-form-item prop="liveUrl">
              <el-input disabled v-model="updDataForm.liveUrl"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="录制时间">
          <el-col>
            <el-form-item prop="recordTime">
              <el-input v-model.number="updDataForm.recordTime">
                <template slot="append">秒</template></el-input
              >
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="录制地址">
          <el-col>
            <el-form-item prop="recordTime">
              <el-input disabled v-model="updDataForm.recordUrl"></el-input>
            </el-form-item>
          </el-col>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="update(updDataForm)"
            >更新</el-button
          >
          <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-card class="box-card">
      <el-form :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-button
            type="primary"
            @click="
              dialogVisible = true;
              form = {};
              type = '新增';
            "
            >新增</el-button
          >
        </el-form-item>
      </el-form>
      <el-table :data="tableData" border style="width: 100%" size="mini">
        <el-table-column prop="id" label="id" width="100"> </el-table-column>
        <el-table-column prop="identify" label="identify" width="100">
        </el-table-column>
        <el-table-column prop="name" label="name" width="100">
        </el-table-column>
        <el-table-column prop="replaySwitch" label="重播开关" width="100">
          <template slot-scope="scope">
            <el-switch
              @change="update(scope.row)"
              :disabled="scope.row.replayTime == 0"
              active-value="ON"
              v-model.number="scope.row.replaySwitch"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="origin" label="来源" width="100">
          <template slot-scope="scope">
            {{ scope.row.origin == "0" ? "海康" : "海康" }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="摄像头状态">
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.status"
              disabled
              @change="update(scope.row)"
              placeholder="摄像头状态"
            >
              <el-option label="关闭" value="INACTIVE"></el-option>
              <el-option label="开启" value="ACTIVE"></el-option>
              <el-option label="重播" value="REPLAYING"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="replayTime" label="重播时间（秒）">
          <template slot-scope="scope">
            {{ scope.row.replayTime }}秒
            <!--  {{scope.row.replayTime || 0}} 秒-->
          </template>
        </el-table-column>
        <el-table-column prop="liveUrl" label="直播地址">
          <template slot-scope="scope">
            <!--  {{scope.row.recordUrl}}-->
            <!-- :disabled="scope.row.status!='ACTIVE'" -->
            <el-button type="primary" @click="playClappr(scope.row.liveUrl)"
              >去观看</el-button
            >
            <el-button
              type="primary"
              :disabled="scope.row.status != 'ACTIVE'"
              @click="copyUrl(scope.row.liveUrl, 1)"
              >复制直播地址</el-button
            >
          </template>
        </el-table-column>

        <el-table-column prop="recordUrl" label="直播操作">
          <template slot-scope="scope">
            <el-button @click="livingStart(scope.row)" type="text" size="small">
              开始</el-button
            >
            <el-button @click="livingStop(scope.row)" type="text" size="small">
              终止</el-button
            >
            <el-button @click="livingReset(scope.row)" type="text" size="small">
              重置</el-button
            >
          </template>
        </el-table-column>

        <el-table-column prop="recordTime" label="录制时间（秒）">
          <template slot-scope="scope">
            {{ scope.row.recordTime }}秒
            <!--  {{scope.row.recordTime || 0}} 秒-->
          </template>
        </el-table-column>

        <!--<el-table-column prop="originUrl" label="源地址"> </el-table-column>
        <el-table-column prop="liveUrl" label="观看地址"> </el-table-column>-->

        <el-table-column prop="recordStatus" label="录制状态">
          <template slot-scope="scope">
            {{
              scope.row.recordStatus == "UNRECORD"
                ? "未录制"
                : scope.row.recordStatus == "RECORDING"
                ? "录制中"
                : scope.row.recordStatus == "RECORDED"
                ? "录制完成"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="recordUrl" label="录制视频观看地址">
          <template slot-scope="scope">
            <!--  {{scope.row.recordUrl}}-->
            <el-button
              type="primary"
              :disabled="scope.row.recordStatus != 'RECORDED'"
              @click="playRecord(scope.row.recordUrl)"
              >去观看</el-button
            >
            <el-button
              type="primary"
              :disabled="scope.row.recordStatus != 'RECORDED'"
              @click="copyUrl(scope.row.recordUrl, 2)"
              >复制视频地址</el-button
            >
          </template>
        </el-table-column>

        <el-table-column label="录制操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="recordDemostart(scope.row)"
              type="text"
              size="small"
            >
              录制视频</el-button
            >
            <el-button
              @click="recordDemoastop(scope.row)"
              type="text"
              size="small"
            >
              停止录制视频</el-button
            >
            <el-button
              @click="recordDemoreset(scope.row)"
              type="text"
              size="small"
            >
              重置</el-button
            >
            <!--  <el-button @click="recordDemostart(scope.row)" v-else-if="scope.row.recordStatus=='RECORDED'" type="text" size="small"
              > 重新录制视频</el-button>-->
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="
                updDialogVisible = true;
                form = {};
                type = '新增';
                updDataForm = scope.row;
              "
            >
              更新</el-button
            >
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @confirm="handleDel(scope.row.id)"
            >
              <el-button slot="reference" type="text" class="ml10" size="small"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tablePage"> -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 200, 300, 400]"
        :page-size="page.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>
      <!-- </div> -->
    </el-card>
  </div>
</template>

<script>
import {
  cameraList,
  camerasave,
  cameraremove,
  recordDemoasyncreset,
  recordDemoasyncstart,
  recordDemoasyncstop,
  camerupdate,
  livingStart,
  livingReset,
  livingStop,
} from "@/api/camera-controller/camera-controller";
import { uuid } from "@/utils/utils";
import flvjs from "flv.js";
import ckPlayer from "@/components/camera/camera.vue";
export default {
  components: { ckPlayer },
  data() {
    return {
      search: {},
      ruleForm: {
        name: "",
        origin: 0,
        replaySwitch: "0",
        replayTime: 1800,
        recordStatus: 0,
        identify: "",
        recordTime: 1200,
      },
      rules: {
        name: [{ required: true, message: "摄像头名称", trigger: "blur" }],
        origin: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        replayTime: [
          {
            required: true,
            message: "请输入重播时间",
            trigger: "blur",
            type: "number",
          },
        ],
      },

      page: {
        total: 0,
        currentPage: 1,
        page: 1,
        size: 10,
      },
      tableData: [],
      player: false,
      dialogVisible: false,
      dialogWatch: false,
      updDialogVisible: false,
      updVideoSee: false,
      hid: "main_" + uuid(),
      updDataForm: {},
     /* pauseisRecordSee: false,*/
      dialogRecord: false,
      recordUrl: "",
      livingUrl: "",
    };
  },
  mounted() {
    // console.log(this.livingUrl)
    // this.livingUrl='https://test.nmgylth.com/living/44/C80169910.m3u8'
   this.getList();
  },
  methods: {
    pauseisVideoSee(){
      console.log(111)
    },
    pauseisRecordSee(){},
    recordDemoreset(item) {
      recordDemoasyncreset({
        id: item.id,
      }).then((res) => {
        this.getList();
      });
    },
    recordDemoastop(item) {
      recordDemoasyncstop({ id: item.id }).then((res) => {
        this.getList();
      });
    },
    recordDemostart(item) {
      if (item.recordTime == 0) {
        this.$message({
          type: "warning",
          message: "录制时间必须大于0!",
        });
        return;
      }

      recordDemoasyncstart({
        id: item.id,
      }).then(() => {
        setTimeout(() => {
          this.getList();
        }, 500);
      });
    },
    playClappr(url) {
      console.log(url,'url')
      this.dialogWatch = true;
      console.log(this.livingUrl, "00000");
      this.livingUrl='00000'
      setTimeout(()=>{
        this.livingUrl='https://test.nmgylth.com/live/'+url+'.m3u8'
      },500)


      console.log(this.livingUrl);
      /*    setTimeout(()=>{

        console.log('https://test.nmgylth.com/living/'+url)
        var videoElement = document.getElementById( this.hid);
         if (flvjs.isSupported() && videoElement) {
              var flvPlayer = flvjs.createPlayer({
                  type: 'flv',
                  url: 'https://test.nmgylth.com/living/'+url+'.flv',
              });
              flvPlayer.attachMediaElement(videoElement);
              flvPlayer.load();
              flvPlayer.play();
          }
       },300)*/

      // "https://open.ys7.com/v3/openlive/E22430497_1_1.m3u8?expire=1664028173&id=363445556247040000&t=b879cd345289678ada085e734d4d5009dc59c27263c8cc802ae0ed6cd061275c&ev=100",
    },
    playRecord(url) {
      console.log(111);
    //  url = url.replace(".flv", ".mp4");
      this.recordUrl = "https://test.nmgylth.com/demo/" + url;
      this.dialogRecord = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.replaySwitch = this.ruleForm.replaySwitch ? "1" : "0";
          camerasave(this.ruleForm).then((res) => {
            console.log(res);
          });
          this.dialogVisible = false;
          setTimeout(() => {
            this.resetForm("ruleForm");
            this.getList();
          }, 200);
        } else {
          console.log("error submit!!");
          console.log(valid);
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleDetail() {
      this.dialogVisible = true;
    },
    handleDel(id) {
      let _this = this;
      cameraremove({ id: id }).then(() => {
        _this.getList();
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    update(item, type) {
      if (type == 2 && item.replayTime == 0) {
        this.$message({
          type: "warning",
          message: "重播时间必须大于0!",
        });
        return;
      }
      if (type == 3 && item.recordTime == 0) {
        this.$message({
          type: "warning",
          message: "录制时间必须大于0!",
        });
        return;
      }

      camerupdate({
        ...item,
        replaySwitch: item.replaySwitch ? 1 : 0,
      }).then((res) => {
        console.log(res);
      });
      // camerupdate
    },
    getList({ page, size } = this.page) {
      cameraList({ page: page, size }).then((res) => {
        console.log(res);
        this.tableData = res.result.records;
        this.page.total = res.result.total;
        this.page.currentPage = res.result.current;
      });
    },
    handleSizeChange(val) {
      this.page.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.page = val;
      this.getList();
    },
    livingStart(item) {
      livingStart({ id: item.id }).then((res) => {
        this.getList();
      });
    },
    livingStop(item) {
      livingStop({ id: item.id }).then((res) => {
        this.getList();
      });
    },

    livingReset(item) {
      livingReset({ id: item.id }).then((res) => {
        this.getList();
      });
    },
    copyUrl(url, type) {
      if (type == 1) {
        url = "https://test.nmgylth.com/living/" + url+".m3u8";
      }
      if (type == 2) {
        url = "https://test.nmgylth.com/demo/" + url;
      }
      const text = document.createElement("textarea"); // 创建节点
      document.body.appendChild(text); // 插入节点

      text.setAttribute("readonly", "readonly");
      text.value = url; // 赋值
      text.setSelectionRange(0, url.length);
      text.select(); // 选中节点
      document.execCommand("copy"); // 执行浏览器复制方法
      if (document.body.removeChild(text)) {
        this.$message({ type: "success", message: "复制成功" });
      } else {
        this.$message({ type: "error", message: "复制失败" });
      }
    },
    changePlayStatus(status) {
      //获取子组件的播放状态
      console.log(status, "status");
      if (status) {
        this.disabledShot = false;
      } else {
        this.disabledShot = true;
        this.sourceUrl = "";
        this.checkedPontsName = "";
      }
    },

  },
};
</script>

<style>
</style>
